import { render, staticRenderFns } from "./DrawerHeader.vue?vue&type=template&id=f39a2b82&scoped=true&"
import script from "./DrawerHeader.vue?vue&type=script&lang=ts&"
export * from "./DrawerHeader.vue?vue&type=script&lang=ts&"
import style0 from "./DrawerHeader.vue?vue&type=style&index=0&id=f39a2b82&lang=scss&scoped=true&"
import style1 from "./DrawerHeader.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f39a2b82",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VBtn,VIcon,VImg,VListItem,VListItemAvatar,VListItemContent,VListItemTitle})
