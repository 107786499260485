
































import Vue from "vue";

export default Vue.extend({
  name: "DrawerHeader",
  data: () => ({
    override: localStorage.getItem("override_mini"),
  }),
  computed: {
    mini: {
      get() {
        return this.$store.getters.mini;
      },
      set() {
        this.$store.dispatch("toggleMini");
      },
    },
    overrideMini() {
      return this.$store.getters.overrideMini;
    },
  },
  methods: {
    minify(m: boolean) {
      this.$store.dispatch("toggleMiniOverride", m);
    },
  },
});
